import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import styles from './MobileMenu.module.scss';

const MobileMenu = () => (
    <StaticQuery
      query={graphql`
        query menuQuery {
            site {
                siteMetadata {
                    menu {
                        label
                        path
                    }
                }
            }
        }
      `}
      render={(
        data
      ) => (
        <nav className={styles['MobileMenu']}>
            <ul className={styles['MobileMenu__list']}>
            {data.site.siteMetadata.menu.map((item) => (
                <li className={styles['MobileMenu__list-item']} key={item.path}>
                <Link
                    to={item.path}
                    className={styles['MobileMenu__list-item-link']}
                    activeClassName={styles['MobileMenu__list-item-link--active']}
                >
                    {item.label}
                </Link>
                </li>
            ))}
            </ul>
        </nav>
      )}
    />
  )

export default MobileMenu;